<template>
  <div class="row items-center q-py-xs">
    <div
      class="col text-left"
      @click="handleClick"
    >
      <div>
        <strong>{{ $t('Pinned message') + ' #' + item.id }}</strong>

        <span class="text-caption">
          - {{ item._embedded.author.name }}
        </span>

        <span class="text-caption"> - {{ $moment(item.created.date).format(appOptions.formats.fullDate) }}</span>
      </div>

      <div class="text-caption">
        {{ item.comment }}
      </div>

      <div v-if="options.showOrderInfo && item.entity['Orderadmin\\Products\\Entity\\AbstractOrder']">
        {{ $t('Order') + ': ' + item.entity['Orderadmin\\Products\\Entity\\AbstractOrder'] }}
      </div>
    </div>

    <q-btn
      text-color="teal"
      size="sm"
      icon="push_pin"
      no-caps
      flat
      unelevated
      @click="handleUnpin"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ChatPinnedItem',
  emits: ['click', 'unpin'],
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    handleClick () {
      this.$emit('click', this.item)
    },
    handleUnpin () {
      this.$emit('unpin', this.item)
    }
  }
}
</script>
