<template>
  <div
    v-if="isIAmSender"
    class="row items-center justify-end"
  >
    <q-btn
      :text-color="$q.dark.isActive ? 'white' : 'dark'"
      size="sm"
      icon="settings"
      no-caps
      flat
      unelevated
      @click="handleClick"
    >
      <q-popup-edit v-model="isOpen">
        <q-btn
          text-color="positive"
          size="sm"
          icon="edit"
          no-caps
          flat
          unelevated
          @click="handleEdit"
        />

        <q-btn
          text-color="teal"
          size="sm"
          icon="push_pin"
          no-caps
          flat
          unelevated
          @click="handlePin"
        />

        <q-btn
          text-color="negative"
          size="sm"
          icon="delete"
          no-caps
          flat
          unelevated
          @click="handleDelete"
        />
      </q-popup-edit>
    </q-btn>

    <q-chat-message
      class="col"
      :name="name"
      :text="[item.comment]"
      :sent="isIAmSender"
      :stamp="stamp"
    />
  </div>

  <div v-else>
    <q-chat-message
      :name="name"
      :text="[item.comment]"
      :sent="isIAmSender"
      :stamp="stamp"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChatItem',
  emits: ['delete', 'pin', 'edit'],
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    item: {
      type: Object,
      required: true
    },
    sender: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    stamp () {
      const date = typeof this.item.created === 'object'
        ? this.item.created.date
        : this.item.created

      return this.$moment(date).format(this.appOptions.formats.fullDate)
    },
    isIAmSender () {
      return this.item._embedded.author.id === this.sender.id
    },
    name () {
      if (this.isIAmSender) {
        if (this.options.showOrderInfo && this.item.entity['Orderadmin\\Products\\Entity\\AbstractOrder']) {
          return `${this.$t('Order')}: ${this.item.entity['Orderadmin\\Products\\Entity\\AbstractOrder']}, Me`
        }

        return 'Me'
      }

      if (this.options.showOrderInfo && this.item.entity['Orderadmin\\Products\\Entity\\AbstractOrder']) {
        return `${this.item._embedded.author.name}, ${this.$t('Order')}: ${this.item.entity['Orderadmin\\Products\\Entity\\AbstractOrder']}`
      }

      return this.item._embedded.author.name
    }
  },
  methods: {
    handleDelete () {
      this.emitEvent('delete')
    },
    handlePin () {
      this.emitEvent('pin')
    },
    handleEdit () {
      this.emitEvent('edit')
    },
    emitEvent (event) {
      this.$emit(event, this.item)
    },
    handleClick () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
